import React, { createRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Accordion.module.scss';
import Icon from './icons/Icon';
import { useWindowResizeDebounced } from '../hooks/utilHooks';
import { SerializedStyles } from '@emotion/react';

type ControlledItem = {
  title: React.ReactNode;
  body: React.ReactNode | ((expanded: boolean) => React.ReactNode);
  expanded: boolean;
};
type UncontrolledItem = {
  title: React.ReactNode;
  body: React.ReactNode | ((expanded: boolean) => React.ReactNode);
  initiallyExpanded?: boolean;
};

type Props = {
  itemHeaderClassName?: string;
  itemHeaderCss?: SerializedStyles;
  renderExpandIcon?(expanded: boolean): React.ReactNode;
} & (
  | {
      controlled?: false;
      items: UncontrolledItem[];
    }
  | {
      controlled: true;
      items: ControlledItem[];
      onItemSelect(index: number): void;
    }
);

const Accordion = (props: Props) => {
  // Fix for TS compile err which can't call map() on a union array type. See https://stackoverflow.com/questions/49510832/typescript-how-to-map-over-union-array-type
  const itemsArr: Array<UncontrolledItem | ControlledItem> = props.items;

  let initialItemsExpanded = {};
  if (!props.controlled) {
    initialItemsExpanded = props.items.reduce<Record<number, boolean>>((itemsExpanded, item, i) => {
      itemsExpanded[i] = !!item.initiallyExpanded;
      return itemsExpanded;
    }, {});
  }

  const innerBodyRefs = useRef(itemsArr.map(() => createRef<HTMLDivElement>()));
  const [itemsBodyHeight, setItemsBodyHeight] = useState<Record<number, number | undefined>>({});
  // Only used for uncontrolled component:
  const [itemsExpanded, setItemsExpanded] = useState<Record<number, boolean>>(initialItemsExpanded);

  useEffect(() => {
    // Store body heights so they can open/close smoothly with the transition
    recordBodyHeightsOfItems();
  }, []);

  useWindowResizeDebounced(() => recordBodyHeightsOfItems());

  const isExpanded = (itemIndex: number) => {
    if (props.controlled) {
      return props.items[itemIndex].expanded;
    } else {
      return itemsExpanded[itemIndex];
    }
  };

  const recordBodyHeightsOfItems = () => {
    for (let i = 0; i < props.items.length; i++) {
      setItemsBodyHeight((state) => ({
        ...state,
        [i]: innerBodyRefs.current[i].current?.clientHeight,
      }));
    }
  };

  function onItemHeaderClick(index: number) {
    // Measure current height of body about to be expanded so we can transition it smoothly
    if (!isExpanded(index)) {
      setItemsBodyHeight((state) => ({
        ...state,
        [index]: innerBodyRefs.current[index].current?.clientHeight,
      }));
    }

    if (props.controlled) {
      props.onItemSelect(index);
    } else {
      setItemsExpanded((state) => ({
        ...state,
        [index]: !state[index],
      }));
    }
  }

  return (
    <div>
      {itemsArr.map((item: ControlledItem | UncontrolledItem, i: number) => {
        const expanded = isExpanded(i);
        const bodyContainerStyle = expanded ? { maxHeight: itemsBodyHeight[i] || 'none' } : undefined;

        return (
          <div className={styles.item} key={i}>
            <div
              className={classNames(styles.header, props.itemHeaderClassName, {
                [styles.headerPadding]: !props.itemHeaderClassName && !props.itemHeaderCss,
              })}
              css={props.itemHeaderCss}
              onClick={() => onItemHeaderClick(i)}
              onKeyDown={(e) => (e.key === 'Enter' ? onItemHeaderClick(i) : undefined)}
              tabIndex={0}
              role="button"
              aria-expanded={expanded}
            >
              {item.title}
              {props.renderExpandIcon ? (
                props.renderExpandIcon(expanded)
              ) : (
                <Icon
                  name="arrowDown"
                  className={classNames({
                    [styles.arrowDown]: !expanded,
                    [styles.arrowUp]: expanded,
                  })}
                  aria-hidden
                />
              )}
            </div>
            <div
              className={classNames(styles.bodyContainer, {
                [styles.bodyContainerExpanded]: expanded,
              })}
              style={bodyContainerStyle}
            >
              <div ref={innerBodyRefs.current[i]}>
                {typeof item.body === 'function' ? item.body(expanded) : item.body}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
