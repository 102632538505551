import React, { ReactNode, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { css } from '@emotion/react';

import philImg from '../media/team/phil.jpg';
import colors from '../../../styles/_colors.module.scss';
import Box from '../../../components/layout/Box';
import { Team, teamMembers, getNumMembers, TeamMember } from '../data/teamMembers';
import TeamMemberCard, { MemberDisplayState, extraBreakpoint2 } from './TeamMemberCard';
import Flex from '../../../components/layout/Flex';

type TeamButtonProps = React.ComponentProps<'button'> & {
  active: boolean;
  numMembers: number;
};

const btnBaseTextColor = colors.gray24;

// Sort team members by name
const sortedTeamMembers = [...teamMembers].sort((a: TeamMember, b: TeamMember) =>
  a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
);
// Add Phil to end of list
sortedTeamMembers.push({
  name: 'Phil Everson',
  emoji: '',
  title: 'Will always be missed',
  photo: philImg,
  bio: null,
});

const TeamButton = ({ active, numMembers, ...btnProps }: TeamButtonProps) => {
  const theme = useTheme();
  const btnMainColor = active ? colors.interactiveVibrantBlue : btnBaseTextColor;

  return (
    <button
      {...btnProps}
      css={css`
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 4rem;
        border-radius: 2rem;
        border: 0.1rem solid ${btnMainColor};
        color: ${btnMainColor};
        background-color: transparent;
        font-family: proxima-nova, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 1.6rem;
        letter-spacing: 0.072rem;
        font-weight: 900;
        padding: 0 0.5rem 0 1.2rem;
        margin: 0 4px 8px 4px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
          background-color: ${colors.interactiveVibrantBlue}40;
        }

        ${extraBreakpoint2} {
          margin-left: 12px;
          margin-right: 12px;
        }
      `}
    >
      {btnProps.children}
      <span
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          margin-left: 0.8rem;
          border: 0.1rem solid ${btnMainColor};
          border-radius: 50%;
          width: 2.8rem;
          height: 2.8rem;
          font-size: 1.4rem;
          letter-spacing: 0.063rem;
          color: ${active ? '#fff' : btnBaseTextColor};
          background-color: ${active ? colors.interactiveVibrantBlue : 'transparent'};
          transition: all 0.25s ease-in-out;
        `}
      >
        {numMembers}
      </span>
    </button>
  );
};

type MemberMeta = {
  [memberName: string]: {
    displayState: MemberDisplayState;
  };
};

function TheTeam() {
  const theme = useTheme();
  const [activeTeam, setActiveTeam] = useState<Team | undefined>();
  const [memberMeta, setMemberMeta] = useState<MemberMeta>(
    sortedTeamMembers.reduce<MemberMeta>((meta, member) => {
      meta[member.name] = {
        displayState: 'photo',
      };
      return meta;
    }, {})
  );

  function renderTeamButton(team: Team, text: ReactNode) {
    return (
      <TeamButton active={activeTeam === team} numMembers={getNumMembers(team)} onClick={() => setActiveTeam(team)}>
        {text}
      </TeamButton>
    );
  }

  function getFilteredTeamMembers() {
    if (!activeTeam) {
      return sortedTeamMembers;
    }

    return sortedTeamMembers.filter((member) => member.team === activeTeam);
  }

  function setMemberDisplayState(memberName: string, displayState: MemberDisplayState) {
    setMemberMeta((state) => {
      const result = {
        ...state,
        [memberName]: {
          ...state[memberName],
          displayState: displayState,
        },
      };

      return result;
    });
  }

  return (
    <Box mt="4rem" maxWidth={{ xs: '33.1rem', sm: 'none' }} ml="auto" mr="auto">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        maxWidth={{ xs: '40rem', sm: 'none' }}
        margin={{ xs: 'auto', sm: 0 }}
      >
        <Box display="inline-flex" justifyContent="center" width={{ xs: '100%', sm: 'auto' }}>
          <TeamButton
            active={activeTeam === undefined}
            numMembers={sortedTeamMembers.length}
            onClick={() => setActiveTeam(undefined)}
          >
            Everyone
          </TeamButton>
        </Box>
        {renderTeamButton('product-development', 'Product Development')}
        {renderTeamButton('revenue', 'Revenue')}
        {renderTeamButton('operations', 'Operations')}
        {renderTeamButton(
          'pets',
          <>
            <span css={{ marginBottom: '0.4rem', marginRight: '0.4rem' }}>🐶</span>Pets
          </>
        )}
      </Box>

      <hr
        css={{
          borderTop: '0.1rem solid #e5e5e5',
          marginTop: '0.8rem',
          marginBottom: '3.2rem',

          [theme.breakpoints.up('lg')]: {
            width: '74.5%',
            marginBottom: '4rem',
          },
        }}
      />

      <Flex
        alignItems="stretch"
        wrap="wrap"
        css={{ marginLeft: '-5px', marginTop: '-10px', width: 'calc(100% + 10px)' }}
      >
        {getFilteredTeamMembers().map((member) => (
          <div
            key={member.name}
            css={{
              padding: '10px 5px',
              flexGrow: 0,
              flexBasis: '100%',
              maxWidth: '100%',
              [theme.breakpoints.up('sm')]: {
                flexBasis: '50%',
                maxWidth: '50%',
              },
              [extraBreakpoint2]: {
                flexBasis: '33.333333%',
                maxWidth: '33.333333%',
              },
              [theme.breakpoints.up('lg')]: {
                flexBasis: '25%',
                maxWidth: '25%',
              },
            }}
          >
            <TeamMemberCard
              member={member}
              displayState={memberMeta[member.name].displayState}
              onDisplayStateChange={(displayState: MemberDisplayState) =>
                setMemberDisplayState(member.name, displayState)
              }
            />
          </div>
        ))}
      </Flex>
    </Box>
  );
}

export default TheTeam;
