import React from 'react';
import classnames from 'classnames';
import SlickCarousel from 'react-slick';
import styles from './Carousel.module.scss';

interface Props extends React.PropsWithChildren<React.ComponentProps<typeof SlickCarousel>> {}

// A wrapper around react-slick to give a default set of styles
const Carousel = React.forwardRef<SlickCarousel, Props>((props, ref) => (
  <SlickCarousel
    {...props}
    ref={ref}
    appendDots={props.dots && !props.appendDots ? (dots) => <div>{dots}</div> : undefined}
    dotsClass={props.dots ? classnames(styles.carouselDots, props.dotsClass) : undefined}
  />
));

export default Carousel;
