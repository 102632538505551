import React, { CSSProperties } from 'react';
import { useTheme } from '@material-ui/core';

import colors from '../../../styles/_colors.module.scss';
import { TeamMember } from '../data/teamMembers';
import Box from '../../../components/layout/Box';
import Image from '../../../components/Image';
import Text from '../../../components/Text';

export type MemberDisplayState = 'photo' | 'video' | 'bio';
type Props = {
  member: TeamMember;
  displayState: MemberDisplayState;
  onDisplayStateChange(displayState: MemberDisplayState): void;
};

export const extraBreakpoint1 = '@media (min-width: 700px)';
export const extraBreakpoint2 = '@media (min-width: 897px)';

const TeamMemberCard = ({ member, displayState, onDisplayStateChange }: Props) => {
  const theme = useTheme();

  const commonCardSideCss: CSSProperties = {
    padding: '14px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };

  function handleCardClick() {
    let newState: MemberDisplayState;

    if (displayState === 'bio') {
      // This assumes that all team members must have a photo
      if (member.mp4Video) {
        newState = 'video';
      } else {
        newState = 'photo';
      }
    } else {
      if (member.bio) {
        newState = 'bio';
      } else {
        newState = displayState;
      }
    }

    onDisplayStateChange(newState);
  }

  function handleMouseEnter() {
    // Ensure team member has video before setting display state to that
    if (member.mp4Video && displayState !== 'bio') {
      onDisplayStateChange('video');
    }
  }

  function handleMouseLeave() {
    if (displayState !== 'bio') {
      onDisplayStateChange('photo');
    }
  }

  return (
    <div
      css={{
        backgroundColor: 'transparent',
        perspective: '1900px', // Give card a perspective for how far the z plane is away from user
        width: '100%',
        height: '100%',
        padding: '6px 0',
        [theme.breakpoints.up('sm')]: {
          margin: 'auto',
          maxWidth: '240px',
        },
        [extraBreakpoint1]: {
          maxWidth: '256px',
        },
        [theme.breakpoints.up('md')]: {
          perspective: '1600px',
          maxWidth: '292px', // use max width to ensure portrait videos show enough of their content
        },
      }}
    >
      <div
        css={{
          position: 'relative',
          borderRadius: '8px',
          border: '1px solid #e5e5e5',
          height: '100%',
          cursor: member.bio ? 'pointer' : 'default',
          WebkitTapHighlightColor: 'transparent',
          transition: 'transform 0.45s ease-out',
          transformStyle: 'preserve-3d',
          transform: displayState === 'bio' ? 'rotateY(-180deg)' : 'rotateY(0deg)',
          backgroundColor: '#fff',
        }}
        onClick={handleCardClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Card front */}
        {/* Apparently there's a CSS/browser bug where backface-visibility: hidden doesn't apply in some cases, and setting an opacity <1 along with it works */}
        <div
          css={{
            ...commonCardSideCss,
            backfaceVisibility: 'hidden',
            opacity: displayState === 'bio' ? 0.99 : 1,
          }}
        >
          <Box
            position="relative"
            width="100%"
            height={{ xs: '305px', sm: '252px', md: '256px', lg: '248px' }}
            mb="1.6rem"
          >
            <Image
              src={member.photo}
              width="241"
              height="245"
              alt={member.name}
              css={{
                display: 'inline',
                borderRadius: '6px',
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%',
                backfaceVisibility: 'hidden',
              }}
            />
            {member.mp4Video && (
              <video
                muted
                autoPlay
                preload="auto"
                loop
                playsInline
                width="100%"
                height="100%"
                css={{
                  display: 'none',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  borderRadius: '6px',
                  verticalAlign: 'top',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  backfaceVisibility: 'hidden',
                  [theme.breakpoints.up('md')]: {
                    // video only plays on large screens
                    display: displayState === 'video' || displayState === 'bio' ? 'inline' : 'none',
                  },
                }}
              >
                <source src={member.mp4Video} type="video/mp4"></source>
              </video>
            )}
          </Box>

          <h4
            css={{
              fontSize: '1.8rem',
              fontWeight: 900,
              lineHeight: '1.25',
              letterSpacing: '0.08rem',
              marginTop: 0,
              marginBottom: '0.4rem',

              [theme.breakpoints.up('lg')]: {
                fontSize: '2rem',
                lineHeight: '1.3',
                letterSpacing: '0.05rem',
              },
            }}
          >
            {member.name} {member.emoji}
          </h4>
          <Text el="p" variant="paragraphMd" css={{ marginBottom: '0.4rem' }}>
            {member.title}
          </Text>
        </div>

        {/* Card back */}
        <div
          css={{
            ...commonCardSideCss,
            paddingLeft: '20px',
            paddingRight: '20px',
            backfaceVisibility: 'hidden',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: colors.bgDarkGreen,
            color: '#fff',
            overflowY: 'auto',
            transform: 'rotateY(180deg)',
            [theme.breakpoints.up('sm')]: {
              paddingLeft: '14px',
              paddingRight: '14px',
            },
            [theme.breakpoints.up('lg')]: {
              paddingLeft: '20px',
              paddingRight: '20px',
            },
          }}
        >
          {member.bio}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
