import React from 'react';
import { Theme, useTheme } from '@material-ui/core';
import { ClassNames, css } from '@emotion/react';

import OurValues1 from './media/value1.svg';
import OurValues2 from './media/value2.svg';
import OurValues3 from './media/value3.svg';
import OurValues4 from './media/value4.svg';
import OurValues5 from './media/value5.svg';
import header from './media/header.png';
import header2x from './media/header2x.png';
import header3x from './media/header3x.png';
import WhoExperience from './media/whoAreWe/experience.svg';
import WhoListen from './media/whoAreWe/listen.svg';
import WhoBalance from './media/whoAreWe/balance.svg';
import WhoDoingRight from './media/whoAreWe/doingRight.svg';
import inspiredBg from './media/inspired-bg.png';
import inspiredBgMobile from './media/inspired-bg-mobile.png';

import JoinUs from './media/join-us.svg';
import hiThereBg from './media/hi-there-bg.png';
import hiThereBgMobile from './media/hi-there-bg-mobile.png';

import colors from '../../styles/_colors.module.scss';
import Page, { Section } from '../../components/Page';
import Grid from '../../components/layout/Grid';
import Image from '../../components/Image';
import Link from '../../components/Link';
import Text from '../../components/Text';
import Box from '../../components/layout/Box';
import OurStoryCarousel from './components/OurStoryCarousel';
import TheTeam from './components/TheTeam';
import Accordion from '../../components/Accordion';
import Flex from '../../components/layout/Flex';

const Sect2Desc = (props: React.PropsWithChildren<{}>) => (
  <Text
    el="p"
    variant="paragraphMd"
    css={(theme: Theme) => ({
      maxWidth: '41.8rem',
      marginBottom: '3.8rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '5.6rem',
      },
    })}
  >
    {props.children}
  </Text>
);

const WhoAreWeSVGWrapper = (props: React.PropsWithChildren<{}>) => (
  <Box mb="1.6rem" width={{ xs: '16.8rem', md: '21.6rem' }}>
    {props.children}
  </Box>
);

const WhoAreWeItem = (props: React.PropsWithChildren<{}>) => (
  <Box maxWidth={{ xs: '27.4rem', sm: '41.8rem', md: 'none' }} m={{ xs: 'auto', md: 0 }}>
    {props.children}
  </Box>
);

const ValueBody = (props: React.PropsWithChildren<{}>) => (
  <Text
    el="p"
    variant="paragraphMd"
    css={(theme: Theme) => ({
      paddingBottom: '1.6rem',

      [theme.breakpoints.up('md')]: {
        paddingBottom: '4rem',
        paddingLeft: '10rem',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '70.8rem',
      },
    })}
  >
    {props.children}
  </Text>
);

const ValueHeaderText = (props: React.PropsWithChildren<{}>) => (
  <Text
    el="h3"
    variant="headingSm"
    noMargin
    fontWeight="normal"
    css={(theme: Theme) => ({
      paddingRight: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.8rem',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.2rem',
      },
    })}
  >
    {props.children}
  </Text>
);

const About = () => {
  const theme = useTheme();

  const valuesIconCss = css`
    display: none;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    margin-right: 4rem;

    ${theme.breakpoints.up('md')} {
      display: block;
    }
  `;

  return (
    <Page
      title="About Goosechase"
      description="We think the world needs more fun, so we're building Goosechase for people to engage, delight, and activate their communities with interactive experiences."
    >
      {/*
                Header
            */}
      <Section p={{ xs: '28px 0 64px 0', md: '88px 0 40px 0' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Text el="h1" variant="headingXl">
              You're here <br /> to do good. <br />
              So are we.
            </Text>
            <Text el="p" variant="paragraphLg" css={{ maxWidth: '47rem' }}>
              Our goal is to make the world more fun, playful, and human by helping you create delightful interactive
              experiences for your communities.
            </Text>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={{ xs: '1.6rem', md: 0 }}>
              <Image
                src={header}
                srcSet={`${header2x} 2x, ${header3x} 3x`}
                width="571"
                height="615"
                alt="team photo collage"
              />
            </Box>
          </Grid>
        </Grid>
      </Section>

      {/*
                Who are we 
            */}
      <Section p={{ xs: '48px 0', md: '88px 0' }}>
        <Box
          css={(theme: Theme) => ({
            backgroundColor: '#FFF',
            backgroundImage: `url(${hiThereBgMobile})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: '4.8rem',
            [theme.breakpoints.up('md')]: {
              backgroundImage: `url(${hiThereBg})`,
              padding: '4.8rem',
              borderRadius: 24,
            },
          })}
        >
          <Text
            el="h2"
            variant="headingLg"
            align="center"
            css={(theme: Theme) => ({
              marginBottom: '4.6rem',
              [theme.breakpoints.up('md')]: { marginBottom: '6.4rem' },
            })}
          >
            Hi, we&apos;re Goosechase
          </Text>
          <Box>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} md={6} lg={5}>
                <WhoAreWeItem>
                  <WhoAreWeSVGWrapper>
                    <WhoExperience width="100%" />
                  </WhoAreWeSVGWrapper>
                  <Text el="h3" variant="headingMd" fontWeight="normal">
                    We&apos;ve got experience
                  </Text>
                  <Sect2Desc>
                    …in experiences. With more than a decade of Goosechase under our wing, we know what it takes to
                    create community events and activities your people will love . Ask us; we love talking to our
                    customers!
                  </Sect2Desc>
                </WhoAreWeItem>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <WhoAreWeItem>
                  <WhoAreWeSVGWrapper>
                    <WhoListen width="100%" />
                  </WhoAreWeSVGWrapper>
                  <Text el="h3" variant="headingMd" fontWeight="normal">
                    You talk, we listen
                  </Text>
                  <Sect2Desc>
                    Listening to our customers has led to co-managed Goosechase Experiences, Mission types, in-app
                    messages, post-Experience analytics - for you, by you.
                  </Sect2Desc>
                </WhoAreWeItem>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <WhoAreWeItem>
                  <WhoAreWeSVGWrapper>
                    <WhoBalance width="100%" />
                  </WhoAreWeSVGWrapper>
                  <Text el="h3" variant="headingMd" fontWeight="normal">
                    Work + Play
                  </Text>
                  <Sect2Desc>
                    Surfing in the middle of a Wednesday? A 4-day week and fully remote team means our flock fits work
                    around their lives, and not the other way around.
                  </Sect2Desc>
                </WhoAreWeItem>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <WhoAreWeItem>
                  <WhoAreWeSVGWrapper>
                    <WhoDoingRight width="100%" />
                  </WhoAreWeSVGWrapper>
                  <Text el="h3" variant="headingMd" fontWeight="normal">
                    We&apos;re good eggs
                  </Text>
                  <Sect2Desc>
                    Bootstrapped, profitable and committed to giving back to the community. Three pillars that show we
                    intend to be here for the long haul.
                  </Sect2Desc>
                </WhoAreWeItem>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Section>

      {/*
                More than a scavenger hunt 
            */}
      <Section
        p={{ xs: '100px 0', md: '120px 0' }}
        css={{
          backgroundImage: `url(${inspiredBgMobile})`,
          backgroundPosition: 'center',
          backgroundColor: '#F7FFDE',
          backgroundSize: 'cover',
          [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${inspiredBg})`,
          },
        }}
      >
        <Text
          el="h2"
          variant="headingLg"
          align="center"
          css={{
            margin: '0 auto 1.6rem auto',
            maxWidth: '30rem',

            [theme.breakpoints.up('sm')]: {
              maxWidth: 'none',
              marginBottom: '2.4rem',
            },
          }}
        >
          Inspired by scavenger hunts, evolved by you
        </Text>
        <Text
          el="p"
          variant="paragraphLg"
          align="center"
          css={{
            margin: 'auto',
            maxWidth: '41.4rem',
            [theme.breakpoints.up('sm')]: {
              maxWidth: '70.1rem',
            },
          }}
        >
          Our long-term vision is to be the interactive experience platform that allows people to engage, educate, and
          activate communities through unforgettable experiences.
        </Text>
      </Section>

      {/*
                Our story carousel
            */}
      <Section p={{ xs: '64px 0', md: '88px 0' }} containerProps={{ maxWidth: 'none', disablePadding: true }}>
        <Text el="h2" variant="headingMd" align="center">
          Our story so far
        </Text>
        <Box mt={{ xs: '3.2rem', md: '6.4rem' }}>
          <OurStoryCarousel />
        </Box>
      </Section>

      {/*
                The team
            */}
      <ClassNames>
        {({ css }) => (
          <Section
            p={{ xs: '48px 0 60px', md: '88px 0' }}
            css={{
              backgroundImage: 'linear-gradient(0deg, rgba(248,247,253,0) 0%, rgba(248,247,253,0.57) 100%)',
            }}
            containerProps={{
              className: css({
                ['@media (min-width: 700px) and (max-width: 896px)']: {
                  padding: '0 46px',
                  maxWidth: '636px',
                },
                ['@media (min-width: 897px) and (max-width: 959px)']: {
                  padding: '0 46px',
                  maxWidth: '924px',
                },
              }),
            }}
          >
            <Text el="h2" variant="headingMd" align="center">
              The bird brains behind it all
            </Text>
            <Text
              el="p"
              variant="paragraphMd"
              align="center"
              css={{
                maxWidth: '27.6rem',
                margin: 'auto',
                [theme.breakpoints.up('sm')]: {
                  maxWidth: '57.2rem',
                },
              }}
            >
              Spread across the map and constantly flying into different timezones, but all on the same page. Find us in
              #food and #pets channels or playing with fire on Giphy roulette.
            </Text>
            <TheTeam />
          </Section>
        )}
      </ClassNames>

      {/*
                Our Values
            */}
      <Section p={{ xs: '48px 0', md: '96px 0' }}>
        <Grid
          container
          spacing={3}
          css={(theme: Theme) => ({
            backgroundColor: '#FFF',
            padding: '4.8rem',
            [theme.breakpoints.up('md')]: {
              padding: '4.8rem',
              borderRadius: 24,
            },
          })}
        >
          <Grid item xs={12} md={5}>
            <Text el="h2" variant="headingMd">
              Our Values
            </Text>
            <Box maxWidth={{ xs: 'none', md: '37rem' }} mb={{ xs: '0.4rem', md: 0 }} pr={{ md: '1.6rem' }}>
              <Text el="p" variant="paragraphMd">
                We don&apos;t take ourselves too seriously, but we&apos;re serious about the commitments we make. We
                champion what we believe and we collaborate, doing our best for our customers and each other.
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box mt={{ md: '5.2rem' }}>
              <Accordion
                itemHeaderCss={css`
                  padding: 15px 0;

                  ${theme.breakpoints.up('md')} {
                    padding: 20px 0;
                  }
                `}
                items={[
                  {
                    title: (
                      <Flex alignItems="center">
                        <OurValues1 css={valuesIconCss} />
                        <ValueHeaderText>We trust each other</ValueHeaderText>
                      </Flex>
                    ),
                    body: (
                      <ValueBody>
                        Radical Candor? More like Radical Gander. As a 100% remote flock, we trust each other to do our
                        best work whenever it works best for us through openness and honesty. Put another way;
                        we&apos;re honest enough to tell you if your jokes are bad but polite enough to laugh anyway!
                      </ValueBody>
                    ),
                    initiallyExpanded: true,
                  },
                  {
                    title: (
                      <Flex alignItems="center">
                        <OurValues2 css={valuesIconCss} />
                        <ValueHeaderText>We put people before profit</ValueHeaderText>
                      </Flex>
                    ),
                    body: (
                      <ValueBody>
                        Work-life balance is at the core of our culture because we know that there&apos;s a lot of fun
                        to be had off the clock - if it&apos;s an adventure, you can count us in! We take the time to
                        recharge, so we have even more energy to put into building a great product and delighting our
                        customers.
                      </ValueBody>
                    ),
                  },
                  {
                    title: (
                      <Flex alignItems="center">
                        <OurValues3 css={valuesIconCss} />
                        <ValueHeaderText>We pay attention to the details</ValueHeaderText>
                      </Flex>
                    ),
                    body: (
                      <ValueBody>
                        Noticing when a teammate&apos;s pet got a new haircut or recognizing a customer for a stellar
                        Mission idea - the strength of a nest is all in the details!
                      </ValueBody>
                    ),
                  },
                  {
                    title: (
                      <Flex alignItems="center">
                        <OurValues4 css={valuesIconCss} />
                        <ValueHeaderText>We are always seeking to improve</ValueHeaderText>
                      </Flex>
                    ),
                    body: (
                      <ValueBody>
                        We love to try new things and experiment with innovative ways to push the boundaries in our
                        roles. We are lifted by each other's encouragement and built on consistent, constructive
                        feedback.
                      </ValueBody>
                    ),
                  },
                  {
                    title: (
                      <Flex alignItems="center">
                        <OurValues5 css={valuesIconCss} />
                        <ValueHeaderText>We are playful and a little weird</ValueHeaderText>
                      </Flex>
                    ),
                    body: (
                      <ValueBody>
                        The best puzzles have different pieces (or else they would be terrible puzzles). We think work
                        should be a place to share hilarious GIFs, Instagram-worthy food and hidden talents. Let's be
                        real; actual geese can be a little bit naughty (we&apos;re looking at you, Canadian geese), so
                        we make it our goal to deliver joy in every interaction to balance the scales a bit.
                      </ValueBody>
                    ),
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Section>

      {/*
                Delight to work here
            */}
      <Section p={{ xs: '48px 0' }}>
        <Box p={{ xs: '32px 28px' }}>
          <Flex alignItems="center" wrap="wrap-reverse" justifyContent="space-between">
            <Box flexBasis={{ xs: '100%', md: '60%', lg: '52%' }}>
              <Box maxWidth={{ md: '50.4rem' }}>
                <Text el="h2" variant="headingSm">
                  Simply put, it&apos;s a delight to work here.
                </Text>
                <Box maxWidth="90%" mb="3.2rem">
                  <Text el="p" variant="paragraphMd">
                    We&apos;re always on the hunt for good people to fly with us. Could that be you?
                  </Text>
                </Box>
              </Box>
              <Link href="https://goosechase.com/careers" variant="btn">
                Join our Flock
              </Link>
            </Box>
            <Box
              flexBasis={{ xs: '100%', md: '40%', lg: '48%' }}
              pl={{ md: '2.8rem' }}
              pb={{ xs: '1rem', md: 0 }}
              display="flex"
              justifyContent="center"
            >
              <JoinUs
                css={{
                  width: '100%',
                  height: '100%',
                  maxWidth: '32rem',
                  [theme.breakpoints.up('md')]: {
                    maxWidth: '37rem',
                  },
                  [theme.breakpoints.up('xl')]: {
                    maxWidth: '42rem',
                  },
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Section>

      {/* legal */}
      <Section css={{ display: 'none', [theme.breakpoints.up('md')]: { display: 'block' } }} p={{ xs: '32px 0' }}>
        <p
          css={{
            fontSize: '1.2rem',
            lineHeight: 1.5,
            letterSpacing: '0.008rem',
            color: '#b0acc0',
            maxWidth: '63.5rem',
            margin: 'auto',
            marginBottom: 0,
            textAlign: 'center',
          }}
        >
          This page features transformative and satirical work and remixes, constituting a fair-use of any copyrighted
          material as provided for in Section 107 of the Copyright Act. All rights reserved to the relevant copyright
          owners.
        </p>
      </Section>
    </Page>
  );
};

export default About;
