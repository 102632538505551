import React, { ReactNode } from 'react';
import { Theme } from '@material-ui/core';

import Box from '../../../components/layout/Box';
import Text from '../../../components/Text';
import colors from '../../../styles/_colors.module.scss';

type Props = {
  img: ReactNode;
  title: string;
  desc: ReactNode;
  active?: boolean;
};

const OurStoryCard = (props: Props) => (
  <Box p={{ xs: '0 0.4rem', md: '0 1.2rem' }}>
    {/* Card */}
    <div
      css={(theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: colors.accentLilac,
        borderRadius: '8px',
        padding: '24px 24px',
        margin: 'auto',
        maxWidth: '45.2rem',
        height: '52.2rem',
        transition: 'all 0.275s ease-in-out',
        opacity: props.active ? 1 : 0.5,
        transform: props.active ? undefined : 'scale(0.9)',

        ['@media (min-width: 375px)']: {
          height: '46.4rem',
        },

        [theme.breakpoints.up('md')]: {
          height: '43.4rem',
          padding: '24px 40px',
        },
      })}
    >
      <Box flexShrink="0">{props.img}</Box>
      <Text
        el="h3"
        variant="headingSm"
        align="center"
        css={{ marginTop: '1.6rem', marginBottom: '1.6rem', color: colors.coreBlack }}
      >
        {props.title}
      </Text>
      <Text el="p" variant="paragraphMd" align="center">
        {props.desc}
      </Text>
    </div>
  </Box>
);

export default OurStoryCard;
