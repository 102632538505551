import React, { ReactNode, useState } from 'react';
import SlickCarousel from 'react-slick';

import Egg from '../media/ourStory/egg.svg';
import Chase from '../media/ourStory/chase.svg';
import Fledgling from '../media/ourStory/fledgling.svg';
import Geese from '../media/ourStory/geese.svg';
import Goose from '../media/ourStory/goose.svg';
import Gosling from '../media/ourStory/gosling.svg';
import Hatchling from '../media/ourStory/hatchling.svg';
import Incubation from '../media/ourStory/incubation.svg';

import Button from '../../../components/Button';
import Carousel from '../../../components/Carousel';
import Icon from '../../../components/icons/Icon';
import Box from '../../../components/layout/Box';
import OurStoryCard from './OurStoryCard';
import { NoSsr } from '@material-ui/core';
import colors from '../../../styles/_colors.module.scss';

type ArrowButtonProps = Omit<React.ComponentProps<typeof Button>, 'color' | 'padding'> & {
  arrowDirection: 'left' | 'right';
};
type OurStoryItem = {
  img: ReactNode;
  title: string;
  desc: ReactNode;
};

const ourStoryItems: OurStoryItem[] = [
  {
    img: <Egg height="14.6rem" />,
    title: 'Egg',
    desc: (
      <>
        The idea for Goosechase originated in 2011 at a hackathon in Waterloo, Canada. We started with the simple goal
        of making scavenger hunts fun and easy to create &ndash; an idea that had wings of its own!
      </>
    ),
  },
  {
    img: <Incubation height="14.6rem" />,
    title: 'Incubation',
    desc: (
      <>
        From the start, we made it our mission to listen. Without investors, we could hear where our customers found
        value and grow thoughtfully instead of rapidly. We wanted to do it right, not fast.
      </>
    ),
  },
  {
    img: <Hatchling height="14.6rem" />,
    title: 'Hatchling',
    desc: (
      <>
        With ease-of-use for both creators and participants at the core of our mission, we built the Goosechase
        Experience Manager for desktop, and iOS and Android apps for participants to download and play for free!
      </>
    ),
  },
  {
    img: <Fledgling height="14.6rem" />,
    title: 'Fledgling',
    desc: (
      <>
        In 2014, Phil Everson joined us and dreamed up Goosechase EDU. Phil&apos;s love for education has led to
        thousands of educators worldwide using Goosechase to teach in an engaging, unique way. We miss you Phil, rest in
        peace.
      </>
    ),
  },
  {
    img: <Gosling height="14.6rem" />,
    title: 'Gosling',
    desc: (
      <>
        Goosechase goes global! With customers spanning 44 countries and Missions being created in over 10 languages, we
        realized that we didn&apos;t all need to be in the same location and made our first remote hire.
      </>
    ),
  },
  {
    img: <Goose height="14.6rem" />,
    title: 'Goose',
    desc: (
      <>
        As we continue to mature, leaving the start-up nest to become a more established organization, we add structure
        and process to action feedback from our customers and hire more amazing talent more quickly.
      </>
    ),
  },
  {
    img: <Geese height="14.6rem" />,
    title: 'Geese',
    desc: (
      <>
        Our flock grows from a few geese to many geese, located all around the world. We now have thousands of
        customers; from Fortune 100 companies to educational institutions, cities, charities, museums and festivals.
      </>
    ),
  },
  {
    img: <Chase height="14.6rem" />,
    title: 'The Chase',
    desc: (
      <>
        Scavenger hunts will always be our inspiration, but our true mission is to connect people through experiences,
        and there&apos;s a lot more of that to come!
      </>
    ),
  },
];

const ArrowButton = ({ arrowDirection, ...btnProps }: ArrowButtonProps) => (
  <Button
    {...btnProps}
    padding="light"
    css={{
      backgroundColor: colors.interactiveHighlighterYellow,
      color: colors.interactiveVibrantBlue,
      flexShrink: 0,
      borderRadius: '50%',
      touchAction: 'manipulation', // to disable double tap zoom on mobile devices
      transform: arrowDirection === 'left' ? 'rotate(180deg)' : undefined,
    }}
    aria-label={`${arrowDirection === 'left' ? 'Previous' : 'Next'} story`}
  >
    {btnProps.children}
  </Button>
);

const OurStoryCarousel = () => {
  const carouselRef = React.createRef<SlickCarousel>();
  const [activeSlide, setActiveSlide] = useState(0);

  const totalSlides = ourStoryItems.length;

  function onLeftArrowClick() {
    carouselRef.current?.slickPrev();
  }

  function onRightArrowClick() {
    carouselRef.current?.slickNext();
  }

  function handleBeforeChange(currSlide: number, nextSlide: number) {
    setActiveSlide(nextSlide);
  }

  return (
    <NoSsr fallback={<div css={{ width: '100%', height: '52.2rem', backgroundColor: 'transparent' }}></div>}>
      <div>
        {/* 
                    NOTE: there's a bug with slick carousel that won't change the current 
                    slide properly when slidesToShow > 1 and infinite=false
                */}
        <Carousel
          ref={carouselRef}
          infinite={false}
          arrows={false}
          centerMode
          centerPadding="100px"
          slidesToShow={1}
          speed={325}
          touchThreshold={15}
          responsive={[
            {
              breakpoint: 2561,
              settings: {
                slidesToShow: 1,
                centerPadding: '750px',
              },
            },
            {
              breakpoint: 1921,
              settings: {
                slidesToShow: 1,
                centerPadding: '700px',
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 1,
                centerPadding: '520px',
              },
            },
            {
              breakpoint: 1550,
              settings: {
                slidesToShow: 1,
                centerPadding: '420px',
              },
            },
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 1,
                centerPadding: '330px',
              },
            },
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 1,
                centerPadding: '290px',
              },
            },
            {
              breakpoint: 1026,
              settings: {
                slidesToShow: 1,
                centerPadding: '240px',
              },
            },
            {
              breakpoint: 850,
              settings: {
                slidesToShow: 1,
                centerPadding: '160px',
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                centerPadding: '100px',
              },
            },
            {
              breakpoint: 520,
              settings: {
                slidesToShow: 1,
                centerPadding: '60px',
              },
            },
            {
              breakpoint: 400,
              settings: {
                slidesToShow: 1,
                centerPadding: '45px',
              },
            },
          ]}
          beforeChange={handleBeforeChange}
        >
          {ourStoryItems.map((item, i) => (
            <OurStoryCard {...item} key={i} active={activeSlide === i} />
          ))}
        </Carousel>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth={{ xs: '100%', sm: '50.8rem' }}
          margin="0.8rem auto 0 auto"
          padding="0 16px"
        >
          <ArrowButton arrowDirection="left" onClick={onLeftArrowClick} disabled={activeSlide === 0}>
            <Icon name="arrowRight" height="4rem" />
          </ArrowButton>
          <ArrowButton arrowDirection="right" onClick={onRightArrowClick} disabled={activeSlide === totalSlides - 1}>
            <Icon name="arrowRight" height="4rem" />
          </ArrowButton>
        </Box>
      </div>
    </NoSsr>
  );
};

export default OurStoryCarousel;
